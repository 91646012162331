import { defineStore } from 'pinia';
import StoreIds from './storeIds';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import type { Product } from '~/server/transformers/shop/product/types';
import type { CalculatedTaxesElement as TaxElement } from '~/server/transformers/shop/returns/types';
import type { ShopCartPrice } from '@/server/transformers/shop/cartTransformer';
import {
  useSessionGet,
  useSecureSessionPost,
} from '~/composables/dataFetching/genericFetchers';
import type { Result as Reasons } from '~/server/api/[site]/shop/return/reasons.get';
import type {
  Result as ReturnItems,
  PostBody as Filter,
} from '~/server/api/[site]/shop/return/returnItems.post';
import type { Result as Methods } from '~/server/api/[site]/shop/return/methods.get';
import type {
  PostBody as CreateReturnPostBody,
  Result as CreateReturnResult,
} from '~/server/api/[site]/shop/return/create.post';
import type { CheckoutStoreStep } from './utils/types';
import { useReservation } from './useReserevation';
import { handleLoadingError } from '~/utils/handleLoadingError';
import { useUserContext } from '~/stores/useUserContext';
import { useUserAddress } from './useUserAddress';
import type { CountryCode } from '~~/src/components/formFields/types';
import dayjs, { getMonthLocale } from '@/helpers/dayJs';
import { HintKeys } from '~/components/shop/pdp/components/hints/types';
import isEqual from 'lodash/isEqual';
import type { ErrorIdent } from '~/@types/errorIdents';
import useGetDebugData from '@/components/debug/useGetDebugData';

export interface ReturnItem {
  lineItemId: string;
  product: Product;
  amount: number;
  price: {
    unitPrice: number;
    totalPrice: number;
    taxRules: LineItem['priceDefinition']['taxRules'];
  };
  returnReasonId: string;
  lineItem: LineItem;
}
export interface ReturnData {
  items: ReturnItem[];
  methodId: string;
  methodDetails: {
    glsData: {
      companyName: string;
      contactName: string;
      street: string;
      houseNr: string;
      countryCode: CountryCode;
      zipCode: string;
      city: string;
      email: string;
      phone: string;
      pickupDate: Date;
      isValid: boolean;
    };
  };
  returnCreationData?: CreateReturnResult;
}

export enum ReturnCheckoutSteps {
  ITEMS = '/account/returns/checkout/items',
  REVIEW = '/account/returns/checkout/review',
  SHIPPING = '/account/returns/checkout/shipping',
  THANKYOU = '/account/returns/checkout/thank-you',
}
export const stepOrder = [
  ReturnCheckoutSteps.ITEMS,
  ReturnCheckoutSteps.SHIPPING,
  ReturnCheckoutSteps.REVIEW,
  ReturnCheckoutSteps.THANKYOU,
];

export const useReturnCheckout = defineStore(StoreIds.RETURN_CHECKOUT, {
  state: () => ({
    isInitialized: false,
    isLoading: false,
    returnItemsData: {
      filter: getDefaultFilterParams(),
      returnItems: {} as ReturnItems,
    },
    returnData: {
      items: [] as ReturnItem[],
      methodId: '',
      methodDetails: {
        glsData: getTemplateGlsData(),
      },
      returnCreationData: null as CreateReturnResult | null,
    } as ReturnData,
    returnReasons: [] as Reasons,
    returnMethods: [] as Methods,
    defaultShippingMethodId: null as string | null,
    subtotal: null as string | null,
    returnShippingCost: null as string | null,
    amountIncludingVat: null as string | null,
    amount: null as string | null,
    vat: null as string | null,
    calculatedTaxes: [] as TaxElement[],
    submitErrors: ref<ErrorIdent[]>([]),
    fetchError: false,
    hints: {
      showErrors: false,
      showWarnings: false,
      showInfo: false,
    },
    _meta: {
      debug: null,
    },
  }),
  actions: {
    async init(refresh?: boolean) {
      if (this._meta.debug === null) {
        this._meta.debug = useGetDebugData()?.value?.store.debug ?? false;
      }
      if (this.isInitialized && !refresh) return;

      this._d('Initializing...', 'INFO');

      await this.loadActions().returnReasons();
      await this.loadActions().returnMethods();

      this.isInitialized = true;
      this.isLoading = false;
    },
    loadActions() {
      return {
        /**@Hint Doesnt save the page in the store yet */
        returns: async (filterParams: {
          orderId?: string;
          page: number;
          itemsPerPage: number;
        }): Promise<ReturnItems> => {
          this.isLoading = true;
          try {
            const returnItems = await useSecureSessionPost<ReturnItems>(
              `/api/${useSiteIdent()}/shop/return/returnItems`,
              {
                ...filterParams,
                orderId: filterParams.orderId?.trim() ?? null,
              },
            );
            if (returnItems && typeof returnItems === 'object') {
              this.returnItemsData = {
                filter: filterParams,
                returnItems: returnItems,
              };
            }
            return this.returnItemsData.returnItems;
          } catch (e) {
            this.fetchError = true;
            handleLoadingError(e);
          } finally {
            this.isLoading = false;
          }
        },
        allReturns: async (refresh = false) => {
          if (
            isEqual(this.returnItemsData.filter, getDefaultFilterParams()) &&
            this.returnItemsData.returnItems?.elements?.length &&
            !refresh
          ) {
            return this.returnItemsData.returnItems;
          }
          return await this.loadActions().returns(getDefaultFilterParams());
        },
        returnReasons: async () => {
          if (this.returnReasons.length > 0) return;

          this._d('Loading Return Reasons', 'INFO');
          this.isLoading = true;
          try {
            const returnReasonList = await useSessionGet<Reasons>(
              `/api/${useSiteIdent()}/shop/return/reasons`,
            );
            if (returnReasonList?.length) {
              this.returnReasons = returnReasonList;
            } else {
              throw new Error('No return reasons found');
            }
          } catch (e) {
            this.fetchError = true;
            handleLoadingError(e);
          } finally {
            this.isLoading = false;
          }
        },
        returnMethods: async () => {
          if (this.returnMethods.length > 0) return;

          this._d('Loading Return Methods', 'INFO');
          this.isLoading = true;
          try {
            const returnMethods = await useSessionGet<Methods>(
              `/api/${useSiteIdent()}/shop/return/methods`,
            );
            if (returnMethods?.length) {
              this.returnMethods = returnMethods;
              this.defaultShippingMethodId = returnMethods.find((method) =>
                method.code.includes('self'),
              ).id;
              this.selectShippingMethod(this.defaultShippingMethodId);
            } else {
              throw new Error('No return methods found');
            }
          } catch (e) {
            this.fetchError = true;
            handleLoadingError(e);
          } finally {
            this.isLoading = false;
          }
        },
      };
    },
    async goToStep(step: ReturnCheckoutSteps) {
      this._d(`Navigating to ${step}`, 'INFO');
      if (this.stepStates[step].isAvailable) {
        await navigateTo(step);
        this.resetShowHints();
      } else {
        this._d(`Step ${step} is not available`, 'WARN');
        this.showHints(true, 'ERR');
      }
    },
    itemActions() {
      return {
        getItem: (lineItemId: string) => {
          return this.returnData.items.find(
            (item) => item.lineItemId === lineItemId,
          );
        },
        getAllItems: () => {
          return this.returnData.items;
        },
        isSelected: (itemId: string): boolean => {
          return this.returnData.items.find(
            (item) => item.lineItemId === itemId,
          )
            ? true
            : false;
        },
        checkifAllSelected: (lineItems: LineItem[]): boolean => {
          let allItemsSelected = true;
          for (let i = 0; i < lineItems.length; i++) {
            if (!this.itemActions().isSelected(lineItems[i].itemId)) {
              allItemsSelected = false;
              break;
            }
          }
          return allItemsSelected;
        },
        select: (lineItems: LineItem[]) => {
          lineItems.forEach((lineItem) => {
            const isSelectedItem = this.returnData.items.find(
              (item) => item.lineItemId === lineItem.itemId,
            );
            if (!isSelectedItem) {
              this._d('Selecting Item with id: ' + lineItem.itemId);
              this.returnData.items.push({
                lineItemId: lineItem.itemId,
                product: lineItem.product,
                amount: lineItem.maxReturnableAmount ?? lineItem.quantity,
                price: {
                  unitPrice: lineItem.unitPrice,
                  totalPrice: lineItem.totalPrice,
                  taxRules: lineItem.priceDefinition?.taxRules ?? [],
                },
                returnReasonId: '',
                lineItem: lineItem,
              });

              // Idea: Maybe use the getter for availableReturnMethods and if current method not avail select default
              if (
                lineItem.product?.hints.find(
                  (hint) => hint.key === HintKeys.DANGEROUS_GOOD,
                )
              ) {
                this.selectShippingMethod(this.defaultShippingMethodId);
              }
            }
          });
        },
        unselect: (lineItems: LineItem[]) => {
          lineItems.forEach((lineItem) => {
            this._d('Unselecting Item with id: ' + lineItem.itemId);
            this.returnData.items = this.returnData.items.filter(
              (item) => item.lineItemId !== lineItem.itemId,
            );
          });
        },
        toggleSelect: (lineItem: LineItem[]) => {
          lineItem.forEach((item) => {
            if (this.itemActions().isSelected(item.itemId)) {
              this.itemActions().unselect([item]);
            } else {
              this.itemActions().select([item]);
            }
          });
        },
        update: (
          items: {
            lineItem: LineItem;
            returnAmount: number;
            returnReason: string;
          }[],
        ) => {
          items.forEach(({ lineItem, returnAmount, returnReason }) => {
            const listItemToUpdate = this.returnData.items.find(
              (item) => item.lineItemId === lineItem.itemId,
            );
            if (listItemToUpdate) {
              this._d(
                `Updating Item with id: ${lineItem.itemId} to amount: ${returnAmount} and reason: ${returnReason}`,
              );
              listItemToUpdate.amount = returnAmount;
              listItemToUpdate.returnReasonId = returnReason;
            }
          });
        },
        getSelectedReturnReason: (lineItemId: string) => {
          const reason = this.returnReasons?.filter(
            (reason) =>
              reason.value ===
              this.itemActions().getItem(lineItemId)?.returnReasonId,
          );
          if (reason[0]) {
            return reason[0].value;
          }
          return null;
        },
        getReturnReasons: (lineItem: LineItem) => {
          const reclamation_reason_id = '133c769c163bff8f97748756c866bffd'; // Missing a special tag or something, thats why we need to filter it out by id right now, this ID is for reclamations and not returns
          let reasons = this.returnReasons.filter(
            (reason) => reason.value !== reclamation_reason_id,
          );
          if (
            !lineItem.product?.hints?.find(
              (hint) => hint.key === HintKeys.CLOTHING,
            )
          ) {
            reasons = reasons.filter((reason) => !reason.tags.clothing);
          }
          return reasons;
        },
      };
    },
    selectShippingMethod(methodId: string) {
      this._d(`Selecting Shipping Method with id: ${methodId}`);
      if (!methodId) {
        this._d(`No Shipping-Method-ID provided`, 'WARN');
        return;
      }
      if (!this.availableReturnMethodIds.includes(methodId)) {
        this._d(`Shipping-Method-ID not available`, 'WARN');
        return;
      }
      // If you remove this you need to trigger Form-Validation manually in the GLS form after mounting
      this.returnData.methodDetails.glsData = getTemplateGlsData();
      setGlsDataFromAccountData(this);
      this.returnData.methodId = methodId;
    },
    async createReturn() {
      this._d('Creating Return', 'INFO');
      this.isLoading = true;

      let postBody: CreateReturnPostBody = {
        returnItems: this.returnData.items.map((item) => ({
          vdvDeliveryItemId: item.lineItemId,
          returnReasonId: item.returnReasonId,
          quantity: item.amount,
        })),
        returnShippingMethodId: this.returnData.methodId,
        subtotal: this.subtotal,
        returnShippingCost: this.returnShippingCost,
        amountIncludingVat: this.amountIncludingVat,
        amount: this.amount,
        calculatedTaxes: this.calculatedTaxes,
        vat: this.vat,
      };

      if (
        this.returnMethods
          .find((x) => x.id === this.returnData.methodId)
          .code.includes('gls_pickup')
      ) {
        postBody = {
          ...postBody,
          glsData: getTransformedGlsData(this),
        };
      }

      if (
        this.returnMethods.find((x) => x.id === this.returnData.methodId)
          .code == 'gls'
      ) {
        try {
          await setGlsDataFromAccountData(this);
          postBody = {
            ...postBody,
            glsData: getTransformedGlsData(this),
          };
        } catch (e) {
          this._d('Could not set GLS data', 'ERR');
          throw new Error('Could not set GLS data');
        }
      }

      try {
        const result = await useSecureSessionPost<CreateReturnResult>(
          `/api/${useSiteIdent()}/shop/return/create`,
          postBody,
        );

        if (!result?.returnId) {
          throw new Error(
            "Return couldn't be created, but Server gave no error",
          );
        }

        this.returnData.returnCreationData = result;
        useReservation().addReservationForShopReturn({
          typeHandle: 'shop_return_created',
          shopReturn: result,
        });

        await navigateTo(ReturnCheckoutSteps.THANKYOU);
      } catch (e: any) {
        this.submitErrors = e?.data?.data?.errors ?? [];
        this._d('Could not create return', 'ERR');
        handleLoadingError(e);
      } finally {
        this.isLoading = false;
      }
    },
    clearSubmitError() {
      this.submitErrors = [];
    },
    /** @summary - Show or hide hints in the checkout */
    async showHints(
      show: boolean,
      type: CheckoutStoreStep['hints'][0]['type'],
    ) {
      if (type === 'ERR') this.hints.showErrors = show;
      else if (type === 'WARN') this.hints.showWarnings = show;
      else if (type === 'INFO') {
        this.hints.showInfo = show;
      }
    },
    /** @summary - Hides all hints in the checkout */
    resetShowHints() {
      this.hints.showErrors = false;
      this.hints.showWarnings = false;
      this.hints.showInfo = false;
    },
    getGlsFormData() {
      return this.returnData.methodDetails.glsData;
    },
    getGlsDataValidity() {
      return this.returnData.methodDetails.glsData.isValid;
    },
    setGlsDataValidity(valid: boolean) {
      this.returnData.methodDetails.glsData.isValid = valid;
    },
    _d(text: string, lvl: 'INFO' | 'WARN' | 'ERR' | 'NONE' = 'NONE') {
      const icon = {
        INFO: '[i]\t',
        WARN: '⚠️\t',
        ERR: '❌\t',
        NONE: '\t',
      };
      if (this._meta.debug)
        // eslint-disable-next-line no-console
        console.debug(`🔙\tReturn-Store: \n${icon[lvl]}${text}`);
    },
    _setDebug(t: boolean) {
      this._meta.debug = t;
    },
  },
  getters: {
    //TODO if needed {all:20, orderId1:10, orderId2:10}
    itemsAsLineItems(state): LineItem[] {
      return state.returnData.items.map(
        (item) =>
          ({
            ...item.lineItem,
            quantity: item.amount,
            totalPrice: item.price.unitPrice * item.amount,
            price: {
              ...item.lineItem.price,
              totalPrice: item.price.unitPrice * item.amount,
            },
          }) as LineItem,
      );
    },
    selectedItemCount(state): number {
      return state.returnData.items.length;
    },
    price(state): ShopCartPrice {
      const price: ShopCartPrice = {
        netPrice: 0,
        totalPrice: 0,
        positionPrice: 0,
        calculatedTaxes: [] as TaxElement[],
        freeShippingGap: 0,
        shippingCosts: 0,
        subtotal: 0,
        ambientCosts: 0,
      };
      const taxRateMap = new Map<string, number>([
        ['default', 19],
        ['miniluDe', 19],
        ['miniluNl', 21],
        ['miniluAt', 20],
      ]);

      price.shippingCosts =
        state.returnMethods.find((s) => s.id === state.returnData.methodId)
          ?.price ?? 0;

      price.netPrice -= price.shippingCosts;

      for (const item of state.returnData.items) {
        price.totalPrice += item.price.unitPrice * item.amount;
        price.subtotal += item.price.unitPrice * item.amount;
        price.netPrice += item.price.unitPrice * item.amount;

        if (item.product?.tax) {
          const taxIndex = price.calculatedTaxes.findIndex(
            (x) => x.taxRate === item.product.tax.taxRate,
          );
          const tax =
            item.price.unitPrice *
            item.amount *
            (item.product.tax.taxRate / 100);

          price.totalPrice += tax;
          if (taxIndex !== -1) {
            price.calculatedTaxes[taxIndex].tax += tax;
          } else {
            price.calculatedTaxes.push({
              taxRate: item.product.tax.taxRate,
              tax: tax,
              price: item.price.unitPrice * item.amount,
            });
          }
        }
      }

      if (price.calculatedTaxes?.length > 1) {
        const target = price.calculatedTaxes.find(
          (elem) => elem.taxRate === taxRateMap.get(useSiteIdent()),
        );

        price.calculatedTaxes = [
          ...price.calculatedTaxes.filter(
            (elem) => elem.taxRate != taxRateMap.get(useSiteIdent()),
          ),
          {
            taxRate: target.taxRate,
            tax: target.tax - price.shippingCosts * 0.19,
            price: target.price - price.shippingCosts,
          },
        ];
      } else if (price.calculatedTaxes?.length == 1) {
        price.calculatedTaxes = [
          {
            taxRate: price.calculatedTaxes[0].taxRate,
            tax:
              price.calculatedTaxes[0].tax -
              (price.shippingCosts * price.calculatedTaxes[0].taxRate) / 100,
            price: price.calculatedTaxes[0].price - price.shippingCosts,
          },
        ];
      }

      const taxSum = price?.calculatedTaxes
        ?.reduce((sum, vat) => {
          return sum + vat.tax;
        }, 0)
        .toFixed(2);
      price.totalPrice = price.netPrice + Number(taxSum);

      state.subtotal = price.subtotal.toString();
      state.returnShippingCost = price.shippingCosts.toString();
      state.amount = price.netPrice.toString();
      state.vat = taxSum.toString();
      state.amountIncludingVat = price.totalPrice.toString();
      state.calculatedTaxes = price.calculatedTaxes;
      return price;
    },
    currentStep(): ReturnCheckoutSteps | null {
      const router = useRouter();
      const curPath = router.currentRoute.value.path as ReturnCheckoutSteps;
      if (Object.values(ReturnCheckoutSteps).some((x) => x === curPath)) {
        return curPath as ReturnCheckoutSteps;
      } else {
        return null;
      }
    },
    stepStates(state) {
      const hasSelectedItemsWithNoReason =
        !useReturnCheckout().selectedItemsWithoNoReason.length;
      const stepStates: Record<ReturnCheckoutSteps, CheckoutStoreStep> = {
        [ReturnCheckoutSteps.ITEMS]: {
          isAvailable: true,
          hints: [],
        },
        [ReturnCheckoutSteps.SHIPPING]: {
          isAvailable:
            state.returnData.items.length > 0 && hasSelectedItemsWithNoReason,
          hints: [],
        },
        [ReturnCheckoutSteps.REVIEW]: {
          isAvailable:
            state.returnData.items.length > 0 &&
            hasSelectedItemsWithNoReason &&
            state.returnData.methodId !== '' &&
            (state.returnData.methodDetails.glsData.isValid ||
              useReturnCheckout().selectedShippingMethod?.code !==
                'gls_pickup'),
          hints: [],
        },
        [ReturnCheckoutSteps.THANKYOU]: {
          isAvailable: false,
          hints: [],
        },
      };
      if (hasSelectedItemsWithNoReason) {
        stepStates[ReturnCheckoutSteps.SHIPPING].hints.push({
          type: 'ERR',
          description: 'Please select a return reason for all items',
        });
      }
      return stepStates;
    },
    availableReturnMethodIds(state): string[] {
      const hasDangerousGoods = useReturnCheckout().itemsContainDangerousGoods;
      return state.returnMethods
        .filter(
          (method) =>
            method.active &&
            (method.tags.canHandleDangerousGood || !hasDangerousGoods),
        )
        .map((method) => method.id);
    },
    selectedShippingMethod(state) {
      return (
        state.returnMethods.find(
          (method) => method.id === state.returnData.methodId,
        ) ?? null
      );
    },
    itemsContainDangerousGoods(state) {
      return state.returnData.items.some((item) =>
        item.product?.hints.find(
          (hint) => hint.key === HintKeys.DANGEROUS_GOOD,
        ),
      );
    },
    selectedItemsWithoNoReason(state) {
      return state.returnData.items.filter((item) => !item.returnReasonId);
    },
  },
});

function getTransformedGlsData(
  store: ReturnType<typeof useReturnCheckout>,
): CreateReturnPostBody['glsData'] {
  const base = {
    companyName: '',
    contactName: '',
    street: '',
    countryCode: '',
    zipCode: '',
    city: '',
    email: '',
    phone: '',
    pickupDate: '',
  };

  const pickupDate = store.returnData.methodDetails.glsData.pickupDate;

  const year = pickupDate.getFullYear();
  const month = String(pickupDate.getMonth() + 1).padStart(2, '0');
  const day = String(pickupDate.getDate()).padStart(2, '0');

  base.pickupDate = `${year}-${month}-${day}T12:00:00Z`;

  base.companyName = store.returnData.methodDetails.glsData.companyName;
  base.contactName = store.returnData.methodDetails.glsData.contactName;
  base.street = `${store.returnData.methodDetails.glsData.street}.${store.returnData.methodDetails.glsData.houseNr}`;
  base.countryCode = store.returnData.methodDetails.glsData.countryCode;
  base.zipCode = store.returnData.methodDetails.glsData.zipCode;
  base.city = store.returnData.methodDetails.glsData.city;
  base.phone = store.returnData.methodDetails.glsData.phone;
  base.email = store.returnData.methodDetails.glsData.email;

  return base;
}

async function setGlsDataFromAccountData(
  store: ReturnType<typeof useReturnCheckout>,
) {
  await useUserContext().init();
  await useUserAddress().loadAddresses();
  const userContext = useUserContext();
  const userAddress = useUserAddress();

  if (!userContext.accountData || !userAddress.contactAddress) {
    throw new Error(
      'Could not set GLS data, because account data or address could not be loaded',
    );
  }

  store.returnData.methodDetails.glsData.contactName = `${userContext.accountData.firstName} ${userContext.accountData.lastName}`;
  store.returnData.methodDetails.glsData.companyName =
    userAddress.contactAddress.company ?? userAddress.contactAddress.name1;
  store.returnData.methodDetails.glsData.street =
    userAddress.contactAddress.street;
  store.returnData.methodDetails.glsData.houseNr =
    userAddress.contactAddress.number;
  store.returnData.methodDetails.glsData.countryCode = countryToCountryCode(
    userAddress.contactAddress.country,
  );
  store.returnData.methodDetails.glsData.zipCode =
    userAddress.contactAddress.zip;
  store.returnData.methodDetails.glsData.city = userAddress.contactAddress.city;
  store.returnData.methodDetails.glsData.email =
    userContext.accountData.billingEmail;
  store.returnData.methodDetails.glsData.phone =
    userContext.accountData.phoneNumber;

  function countryToCountryCode(country: string): CountryCode {
    switch (country.toLowerCase()) {
      case 'oesterreich':
      case 'österreich':
        return 'AT';
      case 'niederlande':
        return 'NL';
      default:
      case 'deutschland':
        return 'DE';
    }
  }
}

function getTemplateGlsData(): ReturnData['methodDetails']['glsData'] {
  const pickupDate = new Date();
  pickupDate.setDate(
    pickupDate.getDate() + ((1 + 7 - pickupDate.getDay()) % 7),
  ); // Next Monday

  return {
    companyName: '',
    contactName: '',
    street: '',
    houseNr: '',
    countryCode: 'DE',
    zipCode: '',
    city: '',
    email: '',
    phone: '',
    pickupDate: pickupDate,
    isValid: false,
  };
}

export function transformGlsTimDataToDisplayData(languageCode: string) {
  const glsData = useReturnCheckout().getGlsFormData();
  if (!glsData.pickupDate)
    return {
      date: '',
    };

  const year = dayjs(glsData.pickupDate).format('YYYY');
  const month = getMonthLocale(glsData.pickupDate, languageCode);
  const weekDay = dayjs(glsData.pickupDate).format('dddd');
  const day = dayjs(glsData.pickupDate).format('DD');

  return {
    date: `${weekDay}, ${day}. ${month} ${year}`,
  };
}

export function getDefaultFilterParams(): Filter {
  return {
    orderId: ' ',
    page: 1,
    itemsPerPage: 8,
  };
}
